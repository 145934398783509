import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/MomSaleDetail'
import styled from '@emotion/styled';
import ReviewSticker from '../../components/reviewSticker'

import { Container } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'
import { skyBlue, copper, oceanBlue } from '../../constants/theme';

import hero from '../../images/welcome/for-mom.jpg';
import momSticker1 from '../../images/welcome/mothers-day-1.jpg'
import momSticker2 from '../../images/welcome/mothers-day-2.jpg'
import momSticker3 from '../../images/welcome/mothers-day-3.jpg'
import thesticker1 from '../../images/welcome/sticker-1.png'
import thesticker2 from '../../images/welcome/sticker-2.png'
import thesticker3 from '../../images/welcome/sticker-3.png'

const DesktopImg = styled.img`
  width: 100%;
`

export const SalesBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  p {
    font-size: 20px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 720px;
    margin: auto;
    margin-bottom: 50px;
    b {
      font-family: 'HKNova-ExtraBold';
    }

    a {
      color: ${oceanBlue};
      text-decoration: none;
    }


  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    color: ${skyBlue};
    margin-bottom: 5px;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 21px;
    color: ${copper};
    margin-left: 7px;
  }


  img {
    border-radius: 100%;
  }

`;



const ImageBlock = (props) => (
  <>
    <DesktopImg alt={props.title} src={props.img} />
  </>
)

const SalesBlock = () => (
  <SalesBlockStyle>
    <h3>The Ultimate</h3>
    <h1>Mother's Day Gift Guide</h1>
    <p>The women in our lives do so much for us every day, this Mother's Day give the gift of pain relief. Shop our curated collection of Mom-Approved products today.</p>
  </SalesBlockStyle>
)


const MomPage = () => {
  return (
    <Container>
      <SEO title="Mother's Day Gift Guide" description="The women in our lives do so much for us every day, this Mother's Day give the gift of pain relief. Shop our curated collection of Mom-Approved products today." keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />
      <ImageBlock img={hero} title="Mothers Day" />
      <SalesBlock />

      <ReviewSticker
        showName
        reverse
        to={'/products/copper-compression-elbow-sleeve/'}
        img={ momSticker1 }
        bg={ copper }
        title='Recovery Elbow Sleeve'
        caption="“I bought this for my mom. She is 74 years old and her elbow has been swollen and hurting for a couple months. She says the compression sleeve is helping her pain and inflammation.”"
        credit="— Raymond S"
        sticker={thesticker1}
      />

      <ReviewSticker
        showName
        img={ momSticker2 }
        to={'/products/copper-compression-fit-for-hands/'}
        bg={ '#8bb1d0' }
        title='Half-Finger Gloves'
        caption="“I bought these for my mom, who suffers from Osteoarthritis. Her hands hurt all the time, and it disrupts her sleep. She now wears these gloves all day, and at night. Thank you for making such a great product that gives her relief!”"
        credit="— Diane L"
        sticker={thesticker2}
      />

      <ReviewSticker
        showName
        reverse
        to={'/products/copper-compression-lower-back-lumbar-support-recovery-brace-for-pain/'}
        img={ momSticker3 }
        bg={ '#99d8e1' }
        title='Recovery Back Brace'
        caption="“I bought this for my mother, she has spinal arthritis and loves it.”"
        credit="— Deb T"
        sticker={thesticker3}
      />

      <SaleDetail tag='THE PERFECT GIFTS' title='For The Mothers In Your Life'/>

    </Container>
  )
}

export default MomPage
